(function redirect() {
  let lastHostName;

  function setOrbReferrer() {
    // Pass on the referrer if we have been redirected by IGOR
    window.orb = window.orb || {};

    if (window.name.match(/ orb_fig_referrer=([^ ]*)/)) {
      // TODO: Move to BBC Page
      window.orb.referrer = decodeURIComponent(RegExp.$1);
      window.name = window.name.replace(/ orb_fig_referrer=([^ ]*)/, '');
    }
  }

  function extractLastHostname() {
    // Make sure window.name is always cleared before you redirect
    if (window.name.match(/ orb_fig_last_hostname=([^ ]*)/)) {
      lastHostName = decodeURIComponent(RegExp.$1);
      window.name = window.name.replace(/ orb_fig_last_hostname=([^ ]*)/, '');
    }
  }

  function isRedirectionEnabled() {
    return (
      window.bbcredirection &&
      (window.bbcredirection.geo || window.bbcredirection.device)
    );
  }

  function determineDestinationPage(currentPage, user, page) {
    let destinationPage = currentPage;

    if (window.bbcredirection.geo) {
      if (page.isInternational && user.isDomestic) {
        destinationPage = currentPage.replace(/^(.+?bbc)\.com/i, '$1.co.uk');
      } else if (page.isDomestic && !user.isDomestic) {
        destinationPage = currentPage.replace(/^(.+?bbc)\.co\.uk/i, '$1.com');
      }
    }
    return destinationPage;
  }

  function shouldRedirect(destinationPage, currentPage, referrer) {
    // avoid redirecting to ourselves, or redirecting to the page we just came from
    return (
      destinationPage !== currentPage &&
      referrer !== destinationPage.toLowerCase()
    );
  }

  function init() {
    let user;

    const referrer = (window.document.referrer || '').toLowerCase();
    const host = (window.location.hostname || '').toLowerCase();
    const currentPage = window.location.href || '';

    if (!isRedirectionEnabled() || referrer === currentPage.toLowerCase()) {
      return;
    }

    setOrbReferrer();
    extractLastHostname();

    const page = {
      isDomestic: /(^|\.)bbc\.co\.uk$/i.test(host),
      isInternational: /(^|\.)bbc\.com$/i.test(host),
    };

    window.bbcuser.isUKCombined().then((isUKCombined) => {
      user = {
        isDomestic: isUKCombined,
      };

      const destinationPage = determineDestinationPage(currentPage, user, page);

      if (shouldRedirect(destinationPage, currentPage, referrer)) {
        const redirectedFromCurrent = lastHostName === window.location.hostname;
        const redirectedFromDestination =
          lastHostName && destinationPage.indexOf(lastHostName) > -1;

        try {
          if (!redirectedFromCurrent && !redirectedFromDestination) {
            window.name += ` orb_fig_last_hostname=${window.location.hostname}`;
            window.name += ` orb_fig_referrer=${encodeURIComponent(
              document.referrer
            )}`;
            window.location.replace(destinationPage);
          }
        } catch (e) {
          console.log('redirection_fail');
        }
      }
    });
  }

  init();
})();
